import React from 'react'
import myimg from '../../assets/sreeram.jpg'
import TypeWriterEffect from 'react-typewriter-effect'
import { motion } from 'framer-motion'
import NumberCounter from 'number-counter'
import  { useEffect, useRef } from "react";
import emailjs from '@emailjs/browser'
import './Header.css'



const Header = () => {
  const form = useRef();

  const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_4rinufd', 'template_ribzpsk', form.current, 'hWktX3rIYtl-2hxMk')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
  return (
    <div className="App">
      <div className="age"><span>AGE</span><br /><div></div><span><NumberCounter end ={23} start={10}delay='5' preFix=""/></span></div>
      <div className="text">
        
        <span >I'M </span>
        <span className='whoiam'>
          <TypeWriterEffect
          
            // textStyle={{
            //   fontWeight: 'bold',
            //   fontSize: '3rem',
            // }}
            startDelay={1000}
            cursorColor="#fff100"
            multiText={[
              'SREERAMACHANDRAN',
              'A WEB DEVELOPER',
              'AN ENTHUSIAST',
              'A SELF-LEARNER',
              'SREERAMACHANDRAN',
            ]}
            multiTextDelay={2000}
            typeSpeed={30}
          />
        </span>
        
      </div>
      <div className="right-j">
            <form Ref={form}  className='email-container' onSubmit={sendEmail}>
                <input type="email" name='user_email ' placeholder='enter your email ' />
                <button className='btn btn-j'>Connect</button>
            </form>
        </div>

      <div className="subtext">
        <span>
          I'm a Freelance Web Designer and Developer based in Malappuram,
          Kerala. I strives to build immersive and beautiful web applications
          through carefully crafted code and user-centric design.
        </span>
        
        <button className='btn btn-warning btn-user'><i class="fa fa-user-circle " aria-hidden="true"></i>MORE ABOUT ME</button>
        <button className='btn-2 btn '><i class="fa fa-suitcase btn-suit" aria-hidden="true"></i>MORE ABOUT ME</button>
      </div>
      <div className="header">
      <div className="image-sec">
        <img src={myimg} alt="" className="hero-image" />
        <div className='background-1' ></div>
      <div className="background-2"></div>
        <div>
          <span className="hi">HI THERE !</span>
        </div>
        
      </div>

       
      
      
      
      </div>
      
      
    </div>
  )
}

export default Header
