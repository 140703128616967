
import './css/bootstrap.min.css'
import './App.css';
import Header from './components/Header/Header'
import Navbar from './components/Navbar/Navbar';
function App() {
  return (
    <div className="App">
      <Navbar/>
      <Header/>
    </div>
  );
}

export default App;
