import React from 'react'

import './Navbar.css'
import logo from '../../assets/depositphotos_57451579-stock-illustration-letter-s-logo-icon-design.jpg'
// import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import  { useState, useRef ,menuOpened} from "react";
import { Link } from 'react-scroll';
const Navbar = () => {
  
  const mobile=window.innerWidth<=768? true:false;
  const [menuOpened,setMenuOpened] = useState(false)
  return (
    <div className='header'>
      

<a class="navbar-brand" href="#"><span><img  src={logo} alt="" className='logo'/></span><span>sreeramachandran</span></a>
      {(menuOpened===false && mobile===true)? (
        
          <div style={{backgroundColor:'black',right:'1rem',padding:'0.5rem ',borderRadius:'5px'}}
          onClick={()=>setMenuOpened(true)}>
            <img src={Bars} alt="" style={{width:'2.5rem',height:'2.5rem',}}/></div>

        ):
        <ul className='header-menu'>
            <li><Link onClick={()=>setMenuOpened(false)} activeClass='active' to='header' span={true} smooth={true}>Home</Link></li>
            <li><Link onClick={()=>setMenuOpened(false)} to='Programs' span={true} smooth={true}>news</Link></li>
            <li><Link onClick={()=>setMenuOpened(false)} to='Reasons' span={true} smooth={true}>Photos</Link></li>
            <li><Link onClick={()=>setMenuOpened(false)} to='plans' span={true} smooth={true}>Projects</Link></li>
            <li><Link onClick={()=>setMenuOpened(false)} to='testimonials' span={true} smooth={true}>Interests</Link></li>
        </ul>}
    </div>
  )
}

export default Navbar
